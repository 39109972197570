<template>
  <div id="app">
    <div
      ref="globalCopyClipboard"
      id="global-copy-clipboard"
      :data-clipboard-text="copyText"
      @click="clickCopyClipboard"
    ></div>
    <div v-if="appReady">
      <keep-alive :include="includeList">
        <router-view v-wechat-title="pIdTitle || $route.meta.title || `${brand}`" />
      </keep-alive>
      <!-- <router-view v-if="!$route.meta.keepAlive" v-wechat-title="pIdTitle || $route.meta.title || `${brand}`" /> -->
    </div>
    <!-- <div class="loading-container" v-else>
      <van-loading type="spinner" />
      <div class="tips">正在为您准备商品...</div>
    </div> -->
    <van-notify
      v-if="orderInfo"
      type="success"
      v-model="showNotify"
      background="#fff"
      class-name="justify-between notify"
      color="#000"
    >
      <div class="flex justify-between notify-content" @click="goPay">
        <div class="notify-desc">
          <div class="fs16 bold">商品待兑换通知</div>
          <div class="mt1">您有商品未兑换成功，优惠价{{ orderInfo.goodsTotalAmount }}元</div>
        </div>
        <div class="notify-image">
          <img :src="orderInfo.imgUrl" alt="" />
        </div>
      </div>
    </van-notify>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { Toast, Notify } from 'vant'
import { eventBus } from 'base/utils/eventBus'
import utils from 'base/utils'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import xEnum from 'base/utils/enum'
import request from 'base/utils/request'
import { login, getAdvertiseConfig, getWxSchema } from 'base/api'
// import { presentPointDialog } from 'components'
import { trackUserEvent } from 'base/utils/rp'
import wechatSdk from 'base/utils/wechatJssdk'

// 特殊渠道的 title 优先级最高，其次是品牌定制的 title。
const PID_TITLE_MAPPING = [
  { PID: '836505946816512', TITLE: '兑换商城' },
  { PID: '836362372120576', TITLE: '限时购' },
  { PID: '835417568444416', TITLE: '兑礼商城' },
  { PID: '836554428776448', TITLE: '新年兑换' },
  { PID: '839947678909532', TITLE: '年货换购' },
  { PID: '840163801432994', TITLE: '年货换购' }
]

export default {
  name: 'App',
  components: { [Notify.Component.name]: Notify.Component },
  data() {
    return {
      cookies: {},
      loginFail: 0,
      copyText: '',
      silent: false,
      onSuccess: () => {},
      brand: this.renderBrandElement({ id: 'global.brand' }),
      orderInfo: null,
      showNotify: false,
      interval: null,
      showNewPointDialog: false,
      includeList: [
        'Layout',
        'ActivityBrand',
        'ReturnScore',
        'TimeLimit',
        'CustomService',
        'GoodsSearch',
        'SingleProductActivity'
      ]
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    }),

    ...mapState({
      pId: (state) => state.appState.pId,
      isInWechat: (state) => state.appState.isInWechat,
      isInApp: (state) => state.appState.isInApp,
      isInMini: (state) => state.appState.isInMini,
      appType: (state) => state.appState.appType,
      userId: (state) => state.user.userId,
      points: (state) => state.user.points,
      activePoints: (state) => state.user.points.activePoints,
      authVersion: (state) => state.user.authVersion,
      appReady: (state) => state.appState.appReady,
      isNewUser: (state) => state.user.isNewUser,
      presentPoint: (state) => state.user.presentPoint,
      showPresentPoint: (state) => state.user.showPresentPoint,
      isPointsDialog: (state) => state.appState.isPointsDialog,
      isInAliPay: (state) => state.appState.isInAliPay,
      stepNumber: (state) => state.appState.stepNumber,
      boolOpen: (state) => state.appState.boolOpen
    }),

    pIdTitle() {
      const map = _.find(PID_TITLE_MAPPING, { PID: this.pId })
      return map && map.TITLE ? map.TITLE : this.renderBrandElement({ id: 'global.title' })
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    appReady: function (newVal, oldVal) {
      if (newVal) {
        const currRouterName = this.$route.name
        if (!this.boolOpen && currRouterName !== '404') {
          this.$router.push({ name: '404' })
        }
        // if (
        //   this.isPointsDialog &&
        //   (this.isNewUser || this.showPresentPoint) &&
        //   (this.isInWechat ? this.showNewPointDialog === true : true) &&
        //   _.includes(['首页', '我的订单', '个人中心', '核心活动', 'leftFirst'], currRouterName)
        // ) {
        //   setTimeout(() => {
        //     presentPointDialog({
        //       point: this.presentPoint,
        //       pointName: this.globalPointName,
        //       onClick: () => {
        //         this.finishPresentPoint(this.pId)
        //         this.$router.push({ path: this.renderBrandElement({ id: 'global.presentPointDialogRouterPath' }) })
        //       }
        //     })
        //   }, 1000)
        // }
        // if (
        //   !this.activePoints &&
        //   !this.isNewUser &&
        //   this.renderBrandElement({ id: 'global.enablePresentPointDialogAgain', type: 'boolean' }) &&
        //   _.includes(['首页', '我的订单', '个人中心', '核心活动', 'leftFirst'], currRouterName)
        // ) {
        //   setTimeout(() => {
        //     presentPointDialog({
        //       // TODO: 具体再次赠送的积分数应从后端获取，不应前端写死
        //       pointName: this.globalPointName,
        //       isPresentAgain: true,
        //       onClick: () => {
        //         this.finishPresentPointAgain({ pId: this.pId })
        //         this.$router.push({ path: this.renderBrandElement({ id: 'global.presentPointDialogRouterPath' }) })
        //       }
        //     })
        //   }, 1000)
        // }
      }
    },

    $route: function (val) {
      if (!this.boolOpen && val.name !== '404') {
        this.$router.push({ name: '404' })
      }
    },

    userId: function (newVal) {
      if (newVal && window.__bl) {
        // window.__bl.setConfig({
        //   setUserName: function () {
        //     return newVal
        //   }
        // })
        window.__bl.pipe = [
          // SDK初始化完成后即开启SPA自动解析。
          [
            'setConfig',
            function () {
              return newVal
            }
          ]
        ]
      }
    }
  },

  async created() {
    // user agent必须第一个执行，用于识别当前的浏览器环境，后面会依赖此操作
    this.$store.dispatch('checkUserAgent')

    // 初始化渠道pId，必须放在所有操作之前，后续出示化操作都依赖于这个pId
    let pId = this.getUrlParam('pId') || localStorage.getItem('pId')
    if (pId) {
      const arr = pId.match(/\d{0,20}/g)
      if (arr.length > 0) {
        pId = arr[0]
      }
      this.updatePId(pId)
    }
    const powerType = this.getUrlParam('powerType')
    if (powerType == '1') {
      const userId = this.getUrlParam('userId')
      if (userId) {
        const storageUserId = localStorage.getItem('userId')
        if (userId !== storageUserId) {
          localStorage.setItem('userId', userId)
        }
      }
    }
    // 登录
    if (this.isInWechat) {
      await this.wxAuth()
      wechatSdk.initWechatJs() //加载wx js sdk.js
      const openid = localStorage.getItem('openid')
      // 没有openid的情况下需要重定向，不应该再调login
      if ((!openid || openid === 'null' || openid === 'undefined') && window.__wxjs_environment !== 'miniprogram') {
        return
      }
      // 微信浏览器 跳转推文页
      const scene = this.getUrlParam('scene')
      if (scene === 'proArticle') {
        this.$router.push({ path: '/copyPromotionArticle' })
      }
    }
    await this.auth()
    // this.$store.dispatch('getCornerImg', { pId: this.pId })
    const toMiniProgram = this.getUrlParam('toMiniProgram') || null
    if (toMiniProgram) {
      await this.toMiniProgram({ pId: Number(this.pId), userId: this.userId })
    }
    // this.getAdvertiseConfig()
    // 给外部渠道的链接，需要passPId为 true，这样h5 的 pid 会透传给快应用内。
    const passPId = this.getUrlParam('passPId')
    if (passPId) {
      this.updateAppState({
        key: 'passPId',
        value: JSON.parse(passPId)
      })
    }
    const tag = this.getUrlParam('tag')
    if (tag) {
      this.updateAppState({
        key: 'userTag',
        value: tag
      })
    }

    const zhtsuid = this.getUrlParam('zhtsuid')
    if (zhtsuid) {
      this.updateAppState({
        key: 'zhtsuid',
        value: zhtsuid
      })
    }
    this.updateAppState({
      key: 'enterUrl',
      value: location.href
    })

    const urlAllParams = utils.getUrlAllParams(location.href)
    for (const key in urlAllParams) {
      urlAllParams[key] = _.replace(urlAllParams[key], '%3F', '')
    }
    this.updateAppState({
      key: 'urlAllParams',
      value: urlAllParams
    })

    this.$store.dispatch('initQuickAppConfig')
    this.$store.dispatch('getUserOrderCount')
    // this.$store.dispatch('getTipFloatConfig')
    // this.$store.dispatch('getTabbarFloatImg')

    if (this.$route.name === '我的订单') {
      this.refreshOrderList({ isClassifyList: true })
    }
    eventBus.$on('copy-to-clipboard', this.setClipboard)
    this.handleNotify()
    eventBus.$on('set-order-info', this.handleNotify)
    this.initUserSearchHistoryList()

    eventBus.$on('copy-to-clipboard', this.setClipboard)
    this.getAuthStatusFromAlipay()
    utils.initWebFavicon()
  },

  async beforeDestroy() {
    eventBus.$off('copy-to-clipboard', this.setClipboard)
    eventBus.$off('set-order-info', this.handleNotify)
    clearInterval(this.interval)
    clearTimeout(this.timer)
  },

  methods: {
    ...mapMutations({
      updateAppState: 'UPDATE_APP_STATE',
      updatePId: 'UPDATE_PID',
      saveAuthInfo: 'SAVE_AUTH_INFO',
      setAppReady: 'SET_APP_READY',
      initUserSearchHistoryList: 'INIT_SEARCH_HISTORY_LIST',
      setKey_user: 'SETKEY_USER',
      togglePvFlag: 'TOGGLE_PV_FLAG'
    }),

    ...mapActions(['refreshOrderList', 'finishPresentPoint', 'finishPresentPointAgain']),
    handleNotify() {
      // app创建时和进入结算页面时都要触发函数，要先销毁上一个计时器
      if (this.interval) {
        clearInterval(this.interval)
      }
      if (window.location.pathname === '/vantmall/primary1') return
      this.orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
      if (this.orderInfo) {
        this.interval = setInterval(() => {
          // 在开始弹窗前判断orderInfo还在不在缓存里，不在的话要取消定时器
          if (!JSON.parse(localStorage.getItem('orderInfo'))) {
            clearInterval(this.interval)
            this.orderInfo = null
          }
          if (
            (window.location.pathname == '/vantmall/leftFirst' ||
              window.location.pathname == '/vantmall/mine' ||
              window.location.pathname == '/vantmall/activity/brand' ||
              window.location.pathname.includes('/vantmall/goodsProfile')) &&
            sessionStorage.getItem('isPointCenterDialogShow') == 'false'
          ) {
            this.showNotify = true
          }
          const timer = setTimeout(() => {
            this.showNotify = false
            clearTimeout(timer)
          }, 5000)
        }, 60000)
      }
    },

    async getAdvertiseConfig() {
      const res = await getAdvertiseConfig({ advType: 'feedAdvert', subType: process.env.VUE_APP_BRANCH_ENUM })
      if (res.code === 200 && res.data) {
        res.data.forEach((item) => {
          item.businessKey = JSON.parse(item.businessKey)
        })
        this.updateAppState({
          key: 'feedAdvert',
          value: res.data
        })
      }
    },

    goPay() {
      this.$router.push({
        path: '/payindex',
        query: {
          orderId: this.orderInfo.orderId
        }
      })
      this.showNotify = false
    },

    getSignInDetail() {
      const endDate = new Date()
      endDate.setDate(endDate.getDate() - 1)
      const params = { date: this.parseTime(endDate), endDate: this.parseTime(new Date()) }
      this.$store.dispatch('GetSignInDetail', params)
    },

    async getPoints() {
      await this.$store.dispatch('GetPoints', this.pId)
    },

    getUrlParam(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r) {
        if (r[2] === 'null') {
          return null
        }

        return r[2]
      }

      return null
    },

    deleteUrlParams(link, params, defaultUrl) {
      try {
        const url = new URL(link)
        const searchParams = new URLSearchParams(url.search.slice(1))
        for (const pName of params) {
          searchParams.delete(pName)
        }
        return url.origin + url.pathname + '?' + searchParams.toString()
      } catch (err) {
        console.log(err)
        // 如果出现浏览器兼容问题，则直接返回默认链接
        return defaultUrl
      }
    },

    getFromLocalStorage(key) {
      const value = localStorage.getItem(key)
      try {
        return JSON.parse(value)
      } catch (err) {
        console.log(err)
        return null
      }
    },

    saveWxInfo(wxPayAppId) {
      const timestamp = utils.getUrlParam('timestamp')
      let isWxRedirectUrl = false
      if (timestamp) {
        const now = new Date()
        // 服务器的时间与客户端时间不同步，导致超过时间戳的范围，因此把时间范围放大
        if (now.valueOf() - timestamp <= 20000) {
          isWxRedirectUrl = true
        }
      }
      // 注释unionId相关代码
      // let unionId = localStorage.getItem('unionId')
      let openid = localStorage.getItem('openid')
      // let unionIdFromUrl = utils.getUrlParam('unionId')
      const openidFromUrl = utils.getUrlParam('openid')
      if (isWxRedirectUrl && openidFromUrl) {
        // unionId = unionIdFromUrl
        openid = openidFromUrl
      }
      this.saveAuthInfo({
        openid
      })
      localStorage.setItem('wxPayAppId', wxPayAppId)
      localStorage.setItem('openid', openid)
    },

    async wxAuth() {
      if (window.__wxjs_environment === 'miniprogram') return
      let appIdChanged = false
      const showNewPointDialog = JSON.parse(utils.getUrlParam('showNewPointDialog'))
      if (showNewPointDialog) {
        this.showNewPointDialog = showNewPointDialog
      }
      let wxPayAppId = localStorage.getItem('wxPayAppId')
      try {
        const response = await request({
          url: '/wxPay/getAppId',
          method: 'get'
        })

        const appId = response.data
        if (appId && appId != wxPayAppId) {
          appIdChanged = true
          wxPayAppId = appId
        }
      } catch (err) {
        console.log(err)
      }
      this.saveWxInfo(wxPayAppId)
      const oldAuthVersion = localStorage.getItem('authVersion')
      const authVersionChanged = oldAuthVersion !== this.authVersion
      const openid = localStorage.getItem('openid')
      // userId仍然从cookie里面找一次，用于兼容老版本的机制
      if (
        this.isInWechat &&
        (appIdChanged || authVersionChanged || !openid || openid === 'null' || openid === 'undefined')
      ) {
        // 暂时不用unionId
        // 此分支会重定向到后端接口，并再次重定向到当前访问页面，走Url获取openid和unionId
        // let redirectUri = encodeURIComponent(`${process.env.VUE_APP_BASE_API}/wxPay/token?appId=${wxPayAppId}`)
        // 这里返回给后端的重定向地址必须要将当前链接中的unionId, openid, timestamp删除，否则会导致后端不断追加参数，而每次从url解析的还是最老的参数
        let callbackUrl = utils.deleteUrlParams(
          window.location.href,
          ['unionId', 'openid', 'timestamp', 'code', 'showNewPointDialog'],
          window.location.origin + window.location.pathname
        )
        if (callbackUrl[callbackUrl.length - 1] === '?') {
          callbackUrl = callbackUrl + '&showNewPointDialog=true'
        } else {
          callbackUrl = callbackUrl + '?&showNewPointDialog=true'
        }
        const redirectUri = encodeURIComponent(
          `${process.env.VUE_APP_BASE_API}/api/v7/wxPay/token?appId=${wxPayAppId}&appType=${
            xEnum.ENUM_APP_TYPE.WX_PUB.value
          }&pId=${this.pId}&url=${btoa(encodeURIComponent(callbackUrl))}`
        )
        const state = 123
        window.location.href =
          `https://open.weixin.qq.com/connect/oauth2/authorize?` +
          `appid=${wxPayAppId}&` +
          `redirect_uri=${redirectUri}&` +
          `response_type=code&` +
          `scope=snsapi_base&` +
          `state=${state}&` +
          `connect_redirect=1` +
          `#wechat_redirect`
        return
      }
    },

    async auth() {
      const userId = this.cookie.get('userId') || localStorage.getItem('userId')
      this.saveAuthInfo({ userId })
      const unionId = localStorage.getItem('unionId') || null
      const eventInfo = {
        userId: localStorage.getItem('userId'),
        webUrl: window.location.href,
        wxId: '',
        tempUserId: ''
      }
      if (this.$store.state.appState.pvFlag) {
        trackUserEvent({ eventType: xEnum.ENUM_RP_EVENT_TYPE.ROUTE_TO_PAGE.value, eventInfo })
      }
      await this.mallLogin(userId, unionId)
    },

    async mallLogin(userId, unionId) {
      try {
        const res = await login({
          unionId,
          userId,
          pId: this.pId,
          appType: this.appType
        })
        if (res.code === 200) {
          userId = res.data.userId
          const isNewUser = res.data.isNewUser
          this.setKey_user({ key: 'isNewUser', value: isNewUser })
          localStorage.setItem('userId', res.data.userId)
          // 保存到store和localStorage中
          this.saveAuthInfo({
            ...res.data,
            unionId
          })
          const eventInfo = {
            userId,
            webUrl: window.location.href,
            wxId: '',
            tempUserId: ''
          }
          if (this.$store.state.appState.pvFlag) {
            trackUserEvent({ eventType: xEnum.ENUM_RP_EVENT_TYPE.ROUTE_TO_PAGE.value, eventInfo })
          }
          this.togglePvFlag(false)
          await this.getPoints()

          if (this.isInApp) {
            try {
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'login', content: { userId: userId } }))
            } catch (err) {
              console.log('上报login事件给APP失败', err)
            }
          }
          await this.$store.dispatch('initConfig', { pId: this.pId })
        }
      } catch (err) {
        console.log(err)
      }
    },

    // Clipboard这个库，触发 click 的 dom 跟绑定 data 的 dom，必须是同一个。因此用这种方式做。
    clickCopyClipboard() {
      const clipboard = new Clipboard('#global-copy-clipboard')
      clipboard.on('success', () => {
        if (!this.silent) {
          Toast.success('复制成功')
        }

        this.onSuccess && this.onSuccess()
        // 释放内存
        clipboard.destroy()
        this.onSuccess = () => {}
      })
      clipboard.on('error', () => {
        // 不支持复制
        if (!this.silent) {
          Toast.fail('该浏览器不支持自动复制')
        }
        // 释放内存
        clipboard.destroy()
        this.onSuccess = () => {}
      })
    },

    setClipboard({ content, silent = false, onSuccess = () => {} }) {
      this.copyText = content
      this.silent = silent
      this.onSuccess = onSuccess
      // 必须先把copyText更新到 dom 上，复制才能生效。
      this.$nextTick(() => {
        this.$refs.globalCopyClipboard.click(onSuccess)
      })
    },

    async toMiniProgram(params) {
      const res = await getWxSchema(params)
      if (res.code === 200) {
        location.href = res.data
      }
    },

    getAuthStatusFromAlipay() {
      if (!this.isInAliPay) return
      // 判断是否运行在小程序环境里
      // eslint-disable-next-line no-undef
      my.getEnv((res) => {
        if (res.miniprogram) {
          // eslint-disable-next-line no-undef
          my.postMessage({ action: 'getQuickTrackParams' })
          // eslint-disable-next-line no-undef
          my.onMessage = (e) => {
            if (e && e.method == 'canIUse' && e.data == 'getAddress') {
              // console.log('接收小程序发过来的信息-1', e)
              this.setKey_user({
                key: 'alipayAddressAuth',
                value: e.result
              })
            }
            if (e?.alipayUserId) {
              // eslint-disable-next-line camelcase
              const { aplus_queue } = window
              // eslint-disable-next-line camelcase
              if (!aplus_queue) return
              //如采集用户ID是异步行为，需要先阻止SDK上报，设置BLOCK埋点
              aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_hold', 'BLOCK']
              })

              // 设置 设备ID
              aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_dev_id', e.alipayUserId]
              })

              aplus_queue.push({
                action: 'aplus.appendMetaInfo', //追加全局属性
                arguments: [
                  'globalproperty',
                  {
                    alipay_app_id: e.appId
                  }
                ]
              })

              // 因为采集用户ID是异步行为，故需要先设置BLOCK，再设置START
              // 设置_hold=START后，事先被block住的日志会携带上用户信息逐条发出
              aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_hold', 'START']
              })
            }
          }

          // console.log('postMessage 小程序获取授权')
          // eslint-disable-next-line no-undef
          my.postMessage({ action: 'execMy', method: 'canIUse', data: 'getAddress' })
        }
      })
    }
  }
}
</script>

<style lang="less">
#YSF-BTN-HOLDER {
  #YSF-CUSTOM-ENTRY-7 {
    position: fixed;
    bottom: 73px;
    right: 20px;
    box-shadow: 0px 0px 6px rgba(128, 128, 128, 0.2);
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.background-image {
  display: inline-block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.h2title {
  width: 100%;
  font-size: 20px;
  color: #333;
  text-align: center;
  line-height: 40px;
  height: 40px;
  padding: 10px 0;
}
.h2title span {
  padding-left: 5px;
}
.h2title svg {
  margin-bottom: 1px;
}
.boxli svg {
  position: relative;
  top: 8px;
}
.In_c_Img img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
<style lang="less" scoped>
.loading-container {
  height: 100vh;
  width: 100vw;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
  }

  .tips {
    margin-top: 10px;
    color: #7c7c7c;
  }
}
.notify-content {
  width: 100%;
}
.notify-desc {
  text-align: left;
}
.notify-image {
  width: 48px;
  height: 48px;
  img {
    width: 100%;
    height: 100%;
  }
}
.notify {
  width: 90%;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 12px 16px;
}
</style>
